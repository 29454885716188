body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*::-webkit-scrollbar {
  display: block;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #dedede;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  border-radius: 8px;
}

* {
  outline: none !important;
}

.MuiDrawer-paperAnchorDockedLeft {
  border-right: 1px solid rgba(0, 0, 0, 0.05) !important;
}

.MuiListItem-button {
  height: 57px;
}

.MuiListItem-root.Mui-selected {
  background: linear-gradient(
    60deg,
    rgb(72, 20, 244) -30%,
    rgb(247, 90, 160) 50%,
    rgb(254, 174, 110) 130%
  );
}

.MuiListItem-root.Mui-selected * {
  color: white;
}
.MuiListItemIcon-root svg {
  color: #3e3e46;
}
.MuiListItemText-root span {
  color: #3e3e46;
}

.MuiDivider-root {
  background-color: rgba(0, 0, 0, 0.05) !important;
}
.makeStyles-toolbar-10 {
  justify-content: flex-start !important;
}

.MuiTooltip-popper > * {
  background-color: #fdd8e1 !important;
  color: #ff5078 !important;
  font-size: 11 !important;
}
.MuiTooltip-arrow {
  color: #fdd8e1 !important;
}
/* .MuiMenu-paper {
  top: 86px !important;
  margin-left: 60px;
  width: 100px;
}
.MuiList-root {
  padding: 0 !important;
} */
.MuiPaper-elevation0 {
  box-shadow: 0px 0px 20px 2px rgba(73, 20, 244, 0.16) !important;
}
.MuiTableCell-root {
  border-bottom: 1px solid #e5e5e56b !important;
}
